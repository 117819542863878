/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { ArrowRightIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import { Link, useParams } from 'react-router-dom';
import withUser from '../../redux/Hoc/withUser';
import { getRoofTopById, getUserById } from '../../utils/firebase';
import { USPhoneNumber } from '../../helpers/app';
import { eventReactGTMModule } from '../../utils/analytics';

const RooftopDetailCard = ({ user, isReferralHome = false, gaEventData }) => {
  const [rooftop, setRooftop] = useState({});
  const { userId } = useParams();

  const onRedirectToGoogleMap = (geppopint) => {
    if (isReferralHome) {
      gaEventData({
        event: 'e_google_map',
        area: 'home_card_rooftop'
      });
    } else {
      const tagManagerArgs = {
        event: 'e_google_map',
        area: 'home_card_rooftop'
      };
      eventReactGTMModule(tagManagerArgs);
    }
    window.open(`https://maps.google.com?q=${geppopint._latitude},${geppopint._longitude}`);
  };

  const onClickPhone = (link, type) => {
    if (isReferralHome) {
      gaEventData({
        event: 'e_dealer_contact',
        area: 'home_card_rooftop',
        contact_method: 'call',
        contact_type: type
      });
    } else {
      const tagManagerArgs = {
        event: 'e_dealer_contact',
        area: 'home_card_rooftop',
        contact_method: 'call',
        contact_type: type
      };
      eventReactGTMModule(tagManagerArgs);
    }
    window.open(link);
  };

  const onClickWebsite = (link) => {
    gaEventData({
      event: 'e_dealer_visit',
      area: 'home_card_rooftop',
      external_destination: 'home'
    });
    window.open(link);
  };

  useEffect(() => {
    async function onInit() {
      if (userId !== undefined) {
        const userRes = await getUserById(userId);
        const roofTopData = await getRoofTopById(userRes.rooftop);
        setRooftop(roofTopData);
      } else if (user.id) {
        const roofTopData = await getRoofTopById(user.rooftop);
        setRooftop(roofTopData);
      }
    }
    onInit();
  }, [user]);

  let rooftopAddress = '';
  let rooftopPhone = {};
  if (Object.keys(rooftop).length > 0) {
    rooftopAddress = Object.values(rooftop.address).filter(Boolean).join(', ');
    rooftopPhone = rooftop.phone;
  }

  return (
    <section aria-labelledby="who-to-follow-heading">
      <div className={`${isReferralHome ? '' : 'bg-white rounded-lg shadow-md'}`}>
        <div className="p-6">
          <div className="flow-root">
            <div className="flex-shrink-0 flex items-center mb-8">
              <Link to="/">
                <img className="block h-8 w-auto" src={rooftop.logo} alt={rooftop.name} />
              </Link>
              <span className="ml-3 text-gray-500 text-2xl font-light">|</span>
              <span className="ml-2 font-medium text-[#333333]">{rooftop.name}</span>
            </div>
            <div className="flex-shrink-0 flex items-center mb-5">
              <div onClick={() => onRedirectToGoogleMap(rooftop.geppopint)}>
                <span className="h-8 w-8 rounded-full flex items-center justify-center bg-gray-200">
                  <LocationMarkerIcon className="h-5 w-5 text-[#FF7600]" aria-hidden="true" />
                </span>
              </div>
              <span className="ml-4 text-sm text-[#333333]">{rooftopAddress}</span>
            </div>
            <div className="mt-4">
              <div className="sm:py-3 sm:grid sm:grid-cols-3">
                <dt className="text-sm font-normal text-gray-500">SERVICE</dt>
                <dd className="mt-1 text-sm text-blue-500 sm:mt-0 sm:col-span-2 hover:text-[#5046E4]">
                  <p
                    className="cursor-pointer mt-1 text-sm text-blue-500 sm:mt-0 sm:col-span-2 hover:text-[#5046E4]"
                    onClick={() => onClickPhone(`tel:${rooftopPhone.service}`, 'service')}>
                    <span className="ml-0 md:ml-1">{USPhoneNumber(rooftopPhone.service)}</span>
                  </p>
                </dd>
              </div>
              <div className="sm:py-3 sm:grid sm:grid-cols-3">
                <dt className="text-sm font-normal text-gray-500">SALES</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 hover:text-[#5046E4]">
                  <p
                    className="cursor-pointer mt-1 text-sm text-blue-500 sm:mt-0 sm:col-span-2 hover:text-[#5046E4]"
                    onClick={() => onClickPhone(`tel:${rooftopPhone.sales}`, 'sales')}>
                    <span className="ml-0 md:ml-1">{USPhoneNumber(rooftopPhone.sales)}</span>
                  </p>
                </dd>
              </div>
              <div className="sm:py-3 sm:grid sm:grid-cols-3">
                <dt className="text-sm font-normal text-gray-500">PARTS</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 hover:text-[#5046E4]">
                  <p
                    className="cursor-pointer mt-1 text-sm text-blue-500 sm:mt-0 sm:col-span-2 hover:text-[#5046E4]"
                    onClick={() => onClickPhone(`tel:${rooftopPhone.parts}`, 'parts')}>
                    <span className="ml-0 md:ml-1">{USPhoneNumber(rooftopPhone.parts)}</span>
                  </p>
                </dd>
              </div>
            </div>
          </div>
          {isReferralHome && !isEmpty(rooftop) && (
            <div className="self-end ">
              <p
                onClick={() => onClickWebsite(rooftop.url.main)}
                className="cursor-pointer flex flex-row inline-flex shadow text-white bg-indigo-600 hover:bg-indigo-800 focus:shadow-outline focus:outline-none py-2 px-4 rounded">
                <span className="ml-1">Visit Our Website</span>
                <ArrowRightIcon className=" ml-2 h-6 w-6 text-white" aria-hidden="true" />
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default withUser(RooftopDetailCard);
